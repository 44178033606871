export const APP_NAME = 'global-nav';
export enum COMPONENTS {
  FOOTER = 'Footer',
  HEADER = 'Header',
}
export enum PANELTYPES {
  BOARDS = 'board',
  USER = 'user',
  BROWSE = 'browse',
}
export type PANELTYPES_RIGHT = PANELTYPES.BOARDS | PANELTYPES.USER;
export enum BOARD_PANEL_VIEW {
  CREATE_BOARD = 'create_board',
  RECENT_BOARDS = 'recent_boards',
}
export enum USER_PANEL_VIEW {
  SIGNED_OUT = 'signed_out',
  ACCOUNT = 'account',
}
export type PANEL_VIEW = PANELTYPES.BROWSE | BOARD_PANEL_VIEW | USER_PANEL_VIEW;
export enum PILLTYPES {
  POPULAR = 'Popular',
  TRENDING = 'Trending',
  CURATED = 'Curated',
}
