import React from 'react';
import { lazyProps } from '@unisporkal/react-lazy-component';
import { LocalizationProvider } from '@unisporkal/localization';
import PropTypes from 'prop-types';
import { ModalProvider } from '@unisporkal/modal';

const Translations = lazyProps((props) =>
  import(`locales/${props.siteName}/${props.locale}`)
);

const Providers = ({ siteName, locale, children }) => (
  <Translations
    siteName={siteName}
    locale={locale}
  >
    {(translations) => (
      <LocalizationProvider
        translations={translations}
        locale={locale}
      >
        <ModalProvider>{children}</ModalProvider>
      </LocalizationProvider>
    )}
  </Translations>
);

Providers.propTypes = {
  siteName: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Providers;
