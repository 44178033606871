import React from 'react';
import { withFederation } from '@unisporkal/federation';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import Providers from '../Providers';
import { APP_NAME, COMPONENTS } from '../constants';

const Component = lazyComponent(
  (props) => import(`./components/${props.siteName}/Header`)
);

const Header = ({
  layout,
  currentPage,
  showSearchBarToggle,
  showEnterpriseDropdown,
  locale,
  siteName,
  linkDisabled,
}) => (
  <Providers
    siteName={siteName}
    locale={locale}
  >
    <Component
      siteName={siteName}
      layout={layout}
      currentPage={currentPage}
      showEnterpriseDropdown={
        siteName === 'getty' ? showEnterpriseDropdown : undefined
      }
      showSearchBarToggle={
        siteName === 'istock' ? showSearchBarToggle : undefined
      }
      linkDisabled={linkDisabled}
    />
    <ReactQueryDevtools
      initialIsOpen
      buttonPosition="top-left"
    />
  </Providers>
);

export default withFederation(APP_NAME, COMPONENTS.HEADER, Header);
